.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pagination button {
  padding: 1rem;
  font-size: 1.2rem;
  background: none;
  border: none;
  color: #235185;
  cursor: pointer;
}

.pagination button:hover {
  border-radius: 1.2rem;
  color: white;
  background-color: #235185;
}

.pagination .active-page {
  color: white;
  background-color: #235185;
  border-radius: 1.2rem;
}
